import Fuse from 'fuse.js'
import React, { useReducer, useEffect, useRef } from "react"
import { Link, navigate } from "gatsby"
import { useMatch } from "@reach/router"
import { 
    CornerDownRight as CornerDownRightIcon
} from "react-feather"
import SearchInput from "./search_input"
import "./search.css"

export default function Search({ index })
{
    const { version } = useMatch('/docs/:version/*')

    const input = useRef(null)
    
    const [state, setState] = useReducer((oldState, newState) => {
        return {...oldState, ...newState}
    }, {
        query: '',
        results: [],
        focused: false,
        focusedIndex: -1,
    });

    useEffect(() => {
        const searchKeyListener = (event) => {
            // Ensure the Command or CTRL key is pressed.
            if (! event.metaKey && ! event.ctrlKey) {
                return
            }

            // Ensure we have the input ref to focus on.
            if (! input.current) {
                return
            }

            // Ensure the "K" key is pressed. 
            if (event.keyCode === 75) {
                setState({focused: true})

                input.current.focus()
            }
        }

        window.addEventListener("keydown", searchKeyListener, true);

        return () => {
            window.removeEventListener("keydown", searchKeyListener);
        }
    }, [])

    /**
     * Perform a search.
     * 
     * @param {Object} event 
     * 
     * @return {void}
     */
    function search(event) {
        const query = event.target.value

        setState({
            query: query,
            focusedIndex: -1,
            results: getSearchResults(query),
        })
    }

    /**
     * Get the search results for the specified query.
     * 
     * @param {string} query
     * 
     * @return {array}
     */
    function getSearchResults(query) {
        const fuse = new Fuse(getHeadings(), {
            keys: ['value'],
            threshold: .25
        })

        return fuse.search(query).slice(0, 10)
    }

    /**
     * Get the searchable headings from all of the markdown pages.
     * 
     * @return {array}
     */
    function getHeadings() {
        let result = [];

        const allPages = index
            .edges
            .map(edge => edge.node)
            .filter(({frontmatter}) => frontmatter.slug.split('/')[2] === version);
        
        allPages.forEach(page => {
            page.headings.forEach(heading => {
                result.push({
                    ...heading,
                    slug: page.frontmatter.slug,
                    title: page.frontmatter.title
                })
            })
        })

        return result
    }

    /**
     * Increment the search focus index.
     * 
     * @return {void}
     */
    function incrementFocusedIndex() {
        if (state.focusedIndex < state.results.length - 1) {
            setState({
                focusedIndex: state.focusedIndex + 1
            })
        }
    }

    /**
     * Decrement the search focus index.
     * 
     * @return {void}
     */
    function decrementFocusedIndex() {
        if (state.focusedIndex >= 0) {
            setState({
                focusedIndex: state.focusedIndex - 1
            })
        }
    }

    /**
     * Go to the to the currently focused search result.
     * 
     * @return {void}
     */
    function go() {
        if (state.results.length === 0) {
            return
        }

        let result = state.focusedIndex === -1
            ? state.results[0]
            : state.results[state.focusedIndex]

        navigateToItem(result.item)
    }

    /**
     * Peform navigation to the specified search result item.
     * 
     * @param {Object} item 
     * 
     * @return {void}
     */
    function navigateToItem(item) {
        navigate(`${item.slug}#${item.id}`)

        setState({
            query: '',
            results: [],
        })
    }

    return (
        <div className="relative w-full">
            <SearchInput
                id="search"
                ref={input}
                value={state.query}
                onFocus={() => setState({focused: true})}
                onBlur={() => {
                    setState({
                        focused: false,
                        focusedIndex: -1,
                    })
                }}
                onChange={search}
                onKeyDown={(event) => {
                    switch (event.keyCode) {
                        case 40:
                            // Down.
                            return incrementFocusedIndex()
                        case 38:
                            // Up.
                            return decrementFocusedIndex()
                        case 13:
                            // Enter.
                            return go()
                        default:
                            return
                    }
                }}
                className={`${state.focused ? 'bg-white' : 'bg-gray-200'} ${state.query && state.focused ? 'rounded-t-lg' : 'rounded-lg'}`}
            />

            {state.query && state.focused &&
                <div className="search-container absolute bg-white w-full shadow-lg overflow-y-auto rounded-b-lg" style={{ maxHeight: '30rem' }}>
                    <ul className="text-gray-600">
                        {state.results.length === 0 &&
                            <li className="block px-4 py-2">
                                <span className="p-2 -mx-2">
                                    No results for <span className="font-semibold">{state.query}</span>
                                </span>
                            </li>
                        }

                        {state.results.map(({item}, index) => {
                            let borderClasses = index + 1 !== state.results.length ? 'border-b border-gray-100' : ''
                            let focusedIndexClasses = state.focusedIndex === index ? 'bg-gray-100 text-app-700' : ''

                            return (
                                <li key={index} className={`px-4 py-2 ${borderClasses}`}>
                                    <Link
                                        to={`${item.slug}#${item.id}`}
                                        onMouseOver={() => setState({focusedIndex: index})}
                                        onMouseDown={() => navigateToItem(item)}
                                        className={`block p-2 -mx-2 font-medium text-app-400 hover:text-app-700 hover:bg-gray-100 rounded-lg ${focusedIndexClasses}`}
                                    >
                                        {item.depth === 1
                                            ? <span>{item.title}</span> 
                                            : (
                                                <span className="flex flex-col">
                                                    {item.title}

                                                    <span className="text-gray-600 font-normal flex justify-start pt-2 leading-none">
                                                        <CornerDownRightIcon className="mx-2 h-4 w-4"/>
                                                        {item.value}
                                                    </span>
                                                </span>
                                            )
                                        }
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            }
        </div>
    )
}