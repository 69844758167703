import React from "react"
import { Search as SearchIcon } from "react-feather"

const SearchInput = React.forwardRef((props, ref) =>
{
    /**
     * Determine if the browsers OS is Windows.
     * 
     * @return {boolean}
     */
    function isWindowsOs() {
        return typeof navigator !== 'undefined' && navigator.platform.indexOf('Win') > -1
    }

    return (
        <label htmlFor="search" className="block">
            <span className="sr-only">Search Documentation</span>
            
            <div className="absolute inset-y-0 left-0 flex items-center justify-center px-3 py-2 opacity-50">
                <SearchIcon className="h-5 w-5"/>
            </div>

            <input
                {...props}
                ref={ref}
                type="search"
                placeholder="Search Documentation..."
                className={`search block w-full py-2 pl-10 pr-4 shadow focus:outline-none ${props.className}`}
            />
            <div className="absolute inset-y-0 right-0 flex items-center justify-center px-3 py-2">
                <span className="hidden bg-white sm:block text-gray-400 text-sm leading-5 py-0.5 px-1.5 border border-gray-300 rounded-md">
                <span className="sr-only">Press </span>
                {isWindowsOs()
                    ? (
                        <kbd className="font-sans">
                            <abbr title="CTRL" className="no-underline">CTRL+</abbr>
                        </kbd>
                    ) 
                    : (
                        <kbd className="font-sans">
                            <abbr title="Command" className="no-underline">⌘</abbr>
                        </kbd>
                    )
                }
                <span className="sr-only"> and </span>
                <kbd className="font-sans">K</kbd>
                <span className="sr-only"> to search</span>
                </span>
            </div>
        </label>
    )
})

export default SearchInput