import React from "react"
import { Helmet } from "react-helmet"
import Nav from "../components/nav"
import { StaticQuery, graphql } from "gatsby"
import BodyClassName from "react-body-classname";
import "../css/app.css"

export const AppLayout = ({ isShowingMenu, title, container, reporting, location, children, data }) => {
    return (
        <BodyClassName className={`font-sans text-typo-body ${isShowingMenu ? 'overflow-hidden' : ''}`}>
            <main className="min-h-screen">
                <Helmet>
                    <title>{title} | {data.site.siteMetadata.title}</title>
                    <meta name="robots" content="noindex"></meta>
                    {reporting &&
                        <script type="text/javascript" src="https://iversoft.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/o2joag/b/24/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=ed15dea1"></script>
                    }
                </Helmet>
                
                <Nav index={data.allMarkdownRemark} location={location}/>

                <section className={ container ? 'container' : ''}>
                    {children}
                </section>
            </main>
        </BodyClassName>
    )
}

export const App = (props) => {
    return (
        <StaticQuery
            query={
                graphql`
                    query {
                        site {
                            siteMetadata {
                                title
                            }
                        }
                        allMarkdownRemark {
                            edges {
                                node {
                                    id
                                    frontmatter {
                                        title
                                        slug
                                    }
                                    headings {
                                        depth
                                        value
                                        id
                                    }
                                }
                            }
                        }
                    }
                `
            }
            render={data => {
                return (
                    <AppLayout {...props} data={data}/>
                )
            }}
        />
    )
}

export default App