import React from "react"
import Search from "./search"
import Logo from "../images/logo.png"
import Topbar from "../images/top-bar.jpg"

export default function Nav({index, location})
{
    const isOnHomePage = location === undefined

    return (
        <>
        {isOnHomePage
            ? (
                <div className="container mx-auto flex justify-center items-center h-24">
                    <div className="bg-app-600 rounded-lg p-2 shadow-lg pl-4 py-4">
                        <a href="/">
                            <img className="h-5 w-auto" src={Logo} alt="ImagineAR Logo"/>
                        </a>
                    </div>
                </div>
            )
            : (
                <div className="sticky bg-app-600 top-0 z-50 shadow-lg" style={{ backgroundImage: `url(${Topbar})` }}>
                    <div className="container mx-auto flex justify-between items-center pl-4 py-4">
                        <div className="pr-6 lg:pr-0  lg:w-2/5 xl:w-1/5">
                            <a href="/">
                                <img className="h-7 w-auto" src={Logo} alt="ImagineAR Logo"/>
                            </a>
                        </div>
                        
                        <div className="flex flex-grow lg:w-3/5 xl:w-3/5">
                            <div className="w-full pr-4 lg:px-6 xl:w-3/4 xl:px-12">
                                <Search index={index} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        </>
    )
}